"use client"; // This makes the component a Client Component
import React,{useState,useRef,useEffect} from 'react'
import logo from '../images/ashlok_logo.svg'
import since from '../images/since_1999_icon.svg'
import sos from '../images/sos.svg';
import { debounce } from 'lodash';
import cstyle from "./header.module.css";
import Image from 'next/image';



const Header = () => {
  const [isSticky, setSticky] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('0px');

 
  /*const debouncedHandleScroll = debounce(() => {
    const offset = window.scrollY;
    setSticky(offset <= 0);
  }, 3000); // Adjust the debounce delay as needed*/

  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset <= 0);
   /* if (offset > 0) {
      setSticky(false);
      
    } else {
      setSticky(true);
    }*/
  };

  const debouncedHandleScroll = debounce(handleScroll, 3000);

  useEffect(() => {
    //window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
     // window.removeEventListener('scroll', handleScroll);
     // sticky hidden       opacity-100 top-0                
     window.removeEventListener('scroll',debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  useEffect(() => {
    const updateHeight = () => {
    const screenWidth = window.innerWidth;
    const screenwidthtenpercent=((screenWidth/100)*10)
    if (contentRef.current) {
      //setContentHeight(isSticky ? `${contentRef.current.scrollHeight}px` : '0px');
      let calcheigt =contentRef.current.scrollHeight;
      if (screenWidth < 640) { // For small devices (e.g., less than 640px)
        if (calcheigt < screenwidthtenpercent) calcheigt = screenwidthtenpercent;
      }else{
      if (calcheigt<150)
            calcheigt =150
      else
        calcheigt = contentRef.current.scrollHeight;
     }

      setContentHeight(isSticky ? `${calcheigt}px` : '0px');
    }
  }
  updateHeight();

  // Add resize event listener
  window.addEventListener('resize', updateHeight);

  // Clean up the event listener on component unmount
  return () => window.removeEventListener('resize', updateHeight);
  }, [isSticky]);

  return (
    <div ref={contentRef} 
    className={`w-full  p-2 lg:p-0 flex flex-row  bg-white   text-white transition-all duration-300 ease-in-out ${cstyle.transitionheight}`}
    style={{ height: contentHeight }}
    >
      <section className=' container mx-auto max-w-screen-xl'>
        <section className=' flex  justify-center align-middle py-5'>
      <div className='flex sm:w-1/12 md:basis-1/3  lg:basis-1/3 items-start justify-start '>  
            <Image className='max-w-[70%]' src={sos} alt="Soldiers of safety providing by ashlok safe earthing electrode" />
      </div>
      <div className='flex basis-1/3  sm:justify-start  sm:items-start lg:justify-center lg:items-center'>
            <div className=" sm:w-[110px] lg:w-[200px]  flex  justify-center">
            <Image  className='max-w-1/4 ' src={since} 
            width={0}
            height={0}
             alt="Ashlok Safe Earthing Electrode Limited logo and 'Since 1999' branding"
            style={{ width: '100%', height: 'auto' }}
            /> 
            </div>
            
        </div>
       
        <div className='flex basis-1/3 items-start justify-end object-cover'>
            <div className="flex items-end justify-end">
            <Image priority className='max-w-1/4' src={logo} 
             width={0}
             height={0}
            alt="Ashlok Safe Earthing Electrode Limited logo representing trusted earthing and lightning protection solutions"
             style={{ width: '100%', height: 'auto' }}
             
             /> 
            
            </div>
        </div>

      </section>
      </section>
      
    </div>
  )
}

export default Header
